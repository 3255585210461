export const handleApiResponse = async (response) => {
    if (response.status === 401) {  // Например, если токен устарел
        window.location.reload();
        return null;
    }
       
    return response;
};

export const authFetch = async (url, options = {}) => {
    try {
        const response = await fetch(url, options);
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Fetch error:', error);
        throw error;
    }
};