export function formattedNumber(value) {
    // Преобразуем входное значение в число
    const numberValue = parseFloat(value);

    // Если число равно 0, возвращаем "0"
    if (numberValue === 0) {
        return "0";
    }

    // Определяем количество знаков после запятой в зависимости от значения
    let formattedValue;

    if (numberValue < 0.0001) {
        // Если число очень маленькое, показываем до 10 знаков после запятой
        formattedValue = numberValue.toFixed(8);
    } else if (numberValue < 0.001) {
        // Если число маленькое, показываем до 5-7 знаков после запятой
        formattedValue = numberValue.toFixed(6);
    } else {
        // В противном случае показываем 3-4 знака после запятой
        formattedValue = numberValue.toFixed(4);
    }

    // Форматируем число, добавляя пробелы между группами из трех цифр
    const [integerPart, fractionalPart] = formattedValue.split('.');
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    // Собираем отформатированное число обратно
    return fractionalPart 
        ? `${formattedIntegerPart}.${fractionalPart}` 
        : formattedIntegerPart;
}