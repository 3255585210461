import './polyfills';
import * as React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/layout";
import Main from "./pages/main";
import NoPage from "./pages/nopage";
import Profile from './pages/profile';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TgBotSetup from './pages/tg-bot-setup';


function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Main />} />
          <Route path='/profile' element={<Profile />} />
          <Route path='/tg-bot-setup' element={<TgBotSetup />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
}


export default App;
