import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import { Onboarding } from "../components/onboarding";
import { MainApiService } from "../services/main.api.service";
import React, { useState, useEffect } from 'react';

const Layout = () => {
    let navigate = useNavigate();
    const location = useLocation();

    // useEffect(() => {
    //     const initData = window?.Telegram?.WebApp?.initData == '' ? "query_id=AAErmW0dAAAAACuZbR2mdCT-&user=%7B%22id%22%3A493721899%2C%22first_name%22%3A%22Ivan%22%2C%22last_name%22%3A%22Sarayan%22%2C%22username%22%3A%22ivansarayan%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1721370103&hash=fce00f8e3fc5cf60c0b29c240887910ed662d6e12f36096b90590fde279f1ce0" : window?.Telegram?.WebApp?.initData;
    //     MainApiService.AppLoading(initData);
    // }, [])

    const initData = window?.Telegram?.WebApp?.initData;

    useEffect(() => {
        if (initData && initData !== '' && window.location.search && window.location.search.includes("pagesettings")) {
            navigate("/tg-bot-setup");
        }
    }, [navigate]);
   
    if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {
        if (!initData || initData === '') {
            return <>
                <div style={{marginTop: "50vh", textAlign: "center"}}>
                    <div className="h4">Telegram only Mini App</div>
                    <div className="h4">Site here</div>
                    <a href="https://wendizel.com" className="h4">https://wendizel.com</a>
                </div>
            </>;
        }
    }

    console.log(window.location.search);


    window.Telegram.WebApp.expand();

    var BackButton = window.Telegram.WebApp.BackButton;
    BackButton.onClick(function () {
        BackButton.hide();
    });

    window.Telegram.WebApp.onEvent('backButtonClicked', function () {
        navigate("/");
    });


    return (
        <>
            <Onboarding></Onboarding>
            <div className="header">
                <div className="header_left">
                    <div className="header_logo"><img width={24} src="/images/money_with_wings.png" alt="logo" /></div>
                    <div className="header_title">BuyBag<span className="header_title_second">Beta</span></div>
                </div>
                <div className="header_right">
                    <div className="header_airdrop"><img width="12" height="12" src="/images/alarm_clock.png" />&nbsp;Airdrop</div>
                    <Link to="/profile"><div className="header_settings"><img width={24} src="/images/settings.png" alt="settings" /></div></Link>
                    {/* {location.pathname === "/" && <Link to="/profile"><div className="header_settings"><img src="/images/settings.png" alt="settings" /></div></Link>}
                    {location.pathname === "/profile" && <Link to="/"><div className="header_back"><img className="caret_left" src="/images/caret.png" alt="settings" />&nbsp;Back</div></Link>} */}
                </div>
            </div>
            <Outlet />
        </>
    )
};

export default Layout;