
import React, { useState, useEffect } from 'react';
import { ActionApiService } from '../services/action.api.service';
import Lottie from 'react-lottie';
import { toast, Bounce } from 'react-toastify';
import loadingAD from '../lotties/loading.json';
import bigfireAD from '../lotties/big_fire.json';
import { TonCenterApiService } from '../services/toncenter.api.service';
import TonWeb from "tonweb";
import { TonHelper } from '../helpers/ton-helper';
import FormattedNumberInput from './formattedNumberInput';
import { formattedNumber } from './formattedNumber';
import fireAD from '../lotties/fire.json';
import { ProfileApiService } from '../services/profile.api.service';

export const Burn = (props) => {
    let allCoinsBalances = props.allCoinsBalances;
    const [activeCoin, setActiveCoin] = useState(TonHelper.WendAsset());
    const [sendAmount, setSendAmount] = useState("0");
    const [sendAmountValid, setSendAmountValid] = useState(false);
    const [sendAmountErrorMessage, setSendAmountErrorMessage] = useState(null);
    const [controlsDisabled, setControlsDisabled] = useState(false);
    const [progress, setProgress] = useState(0);
    const [leaderboard, setLeaderboard] = useState([]);
    const [currentLevel, setCurrentLevel] = useState(1);
    const [coinSelectPopupVisible, setCoinSelectPopupVisible] = useState(false);
    const [inited, setInited] = useState(false);

    let tonBalanceNumber = Number(props.tonBalance.replace(/,/g, '.'));

    const loadingOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingAD,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const bigFireOptions = {
        loop: true,
        autoplay: true,
        animationData: bigfireAD,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const fireOptions = {
        loop: true,
        autoplay: true,
        animationData: fireAD,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    useEffect(() => {
        if (coinSelectPopupVisible) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }

        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [coinSelectPopupVisible]);

    useEffect(() => {
        if (!sendAmount || sendAmount === '') {
            setSendAmountValid(false);
        }

        if (sendAmount && sendAmount != '' && activeCoin) {
            let sendAmountNumber = Number(removeLeadingZerosRegex(sendAmount.replace(/,/g, '.')));
            let isValid = true;
            let errorMessage = '';

            if (activeCoin.balance < sendAmountNumber || allCoinsBalances.find(x => x.address == activeCoin.address)?.balance < sendAmountNumber) {
                isValid = false;
                errorMessage = "Insufficient balance";
            }
            else {
                if (tonBalanceNumber < 0.07) {
                    isValid = false;
                    errorMessage = "Insufficient funds to pay 0.07 TON gas+fee";
                }
            }

            setSendAmountValid(isValid);
            setSendAmountErrorMessage(errorMessage);
        }

    }, [sendAmount, activeCoin]);


    useEffect(() => {
        if (activeCoin) {
            ProfileApiService.GetBurnInfo(activeCoin.address).then(result => {
                if (result === null) {
                    setCurrentLevel(1);
                    setProgress(0);
                    setLeaderboard([]);
                    return;
                }
                setCurrentLevel(result.level);
                setProgress(result.progress);
                setLeaderboard(result.leaderboard);
                /*       if (!inited)
                          setLastBurnAsset(result.lastBurnAsset); */
            });
        }

    }, [activeCoin]);


    function setLastBurnAsset(asset) {
        setInited(true);
        if (activeCoin.address !== asset.address) {
            let existingBalance = allCoinsBalances.find(x => x.address == asset.address);
            if (existingBalance) {
                setActiveCoin(existingBalance);
            }
            else {
                setActiveCoin(asset);
            }
        }
    }

    function assetClick(coin) {
        setActiveCoin(coin);
        setCoinSelectPopupVisible(false);
    }

    function confirmAndBurn() {
        setControlsDisabled(true);
        ActionApiService.Burn(sendAmount, activeCoin.address).then(result => {
            if (result.isSuccess === true) {
                toast.success('Sent!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "dark",
                    transition: Bounce,
                });
                /* closeAndReset(); */
            }
            else {
                toast.error(result.message ?? 'Failed.', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "dark",
                    transition: Bounce,
                });
            }

            setControlsDisabled(false);
        }).catch((error) => {
            setControlsDisabled(false);
        });
    }

    function removeLeadingZerosRegex(str) {
        if (!str)
            return '';

        return str.replace(/^0+(?=\d)/, '');
    }

    return (
        <>
            <div className='burn_section'>
                <div className='burn_fire_crisper'>
                    <Lottie
                        options={bigFireOptions}
                        height={200}
                        width={"auto"}
                        isClickToPauseDisabled={true}
                    />
                    {
                        currentLevel &&
                        <div className='burn_progress'>
                            <div className={"progress_fill " + (progress > 95 ? "progress_fill_right_rounded" : "")} style={{ width: `${progress}%` }} />
                            <div className='burn_progress_level_label'>Level {currentLevel}</div>
                            <div className='burn_progress_level_label'>Level {currentLevel + 1}</div>
                        </div>
                    }
                </div>
                <div className='burn_action_crisper'>
                    <div className="swap_coin_type_fs mt12" onClick={() => setCoinSelectPopupVisible(true)}>
                        <img className="swap_coin_type_icon" src={activeCoin?.image} width={32} height={32} />
                        <div className="swap_coin_type_title">{activeCoin?.shortTitle}</div>
                        <img className='swap_coin_type_caret' src="/images/caret.png" width={24} />
                    </div>
                    <FormattedNumberInput
                        className={"rounded_input mt12 " + (sendAmountErrorMessage && sendAmountErrorMessage !== '' ? 'error' : '')}
                        value={sendAmount}
                        onChange={setSendAmount}
                    />
                    {sendAmountErrorMessage && sendAmountErrorMessage !== '' &&
                        <div className='amount_input_error_message mtxl'>{sendAmountErrorMessage}</div>
                    }

                    <div onClick={() => confirmAndBurn()} className={"default_next_btn btn_red burn_button mt12 df jcc aic " + (sendAmount > 0 && sendAmountValid === true && controlsDisabled === false ? "" : "unclickable")}>
                        <Lottie
                            style={{ margin: "unset", marginTop: -3 }}
                            options={fireOptions}
                            height={20}
                            width={20} />
                        &nbsp;Burn
                    </div>
                </div>
                {leaderboard && leaderboard.length > 0 &&
                    <div className="plain_rounded_list_crisper mtd">
                        <div className='plain_rounded_list_header'>Top burners</div>
                        {leaderboard.sort((a, b) => b.burnedInTon - a.burnedInTon).map((item, index) =>
                            <div key={index} className='plain_rounded_list_item'>
                                <div className="plain_rounded_list_item_left">
                                    <div className='plain_rounded_list_item_title'>{item.username}</div>
                                    <div className='plain_rounded_list_item_subtitle'>Level {item.level}</div>
                                </div>
                                <div className="plain_rounded_list_item_right">
                                    {index > 2 && index + 1}
                                    {index === 0 && <img width={32} height={32} src="/images/1_medal.png" />}
                                    {index === 1 && <img width={32} height={32} src="/images/2_medal.png" />}
                                    {index === 2 && <img width={32} height={32} src="/images/3_medal.png" />}
                                </div>
                            </div>
                        )}

                    </div>
                }
            </div>
            {
                coinSelectPopupVisible &&
                (
                    <div className={`modal_container visible`}>
                        <div onClick={() => { setCoinSelectPopupVisible(false) }} className='modal_overlay_half'></div>
                        <div className='bottom_stuck_modal half_stuck_modal modal_scrollable' style={{ minHeight: 400 }}>
                            <div className='bottom_stuck_modal_content pdside16'>
                                <div className='mtxl'></div>
                                <div className='popup_title'>
                                    Select asset to burn
                                </div>
                                <div className="settings_coin_balances">
                                    {allCoinsBalances != null && allCoinsBalances.length > 0 && allCoinsBalances.filter(coin => coin.balance > 0.0009).sort((a, b) => b.balance - a.balance).map((coin, index) =>
                                        <div className={'asset_item_plain'} key={index} onClick={() => assetClick(coin)}>
                                            <img className='asset_item_image' src={coin?.image} />
                                            <div className='asset_desc'>
                                                <div className='asset_short_title'>{coin?.shortTitle}</div>
                                                <div className='asset_title'>{coin?.title}</div>
                                            </div>
                                            <div className='asset_balance_desc'>
                                                <div className='asset_balance'>{formattedNumber(coin?.balance ?? 0)}</div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>
                    </div>
                )
            }


        </>
    );
}
